import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import SpeakerAddress from "../../../../components/SpeakerAddress";

class ProfileContactInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let { speaker } = this.props;

        let email = _.get(speaker, ["email"]);
        let phone = _.get(speaker, ["phone"]);
        let url = _.get(speaker, ["url"]);
        // let address = _.get(speaker, ["address"]);
        // let address2 = _.get(speaker, ["address2"]);
        let city = _.get(speaker, ["city"]);
        let state = _.get(speaker, ["state"]);
        let zip = _.get(speaker, ["zip"]);
        let country = _.get(speaker, ["country"]);
        // let spkr_name = _.get(speaker, ["name"]);
        return (
            <div>
                {phone && (
                    <div>
                        <i
                            className="fa fa-phone"
                            style={{ marginRight: "0.5rem" }}></i>
                        {phone}
                    </div>
                )}
                {email && (
                    <div>
                        <i
                            className="fa fa-envelope"
                            style={{ marginRight: "0.5rem" }}></i>
                        <span
                            style={{
                                unicodeBidi: "bidi-override",
                                direction: "rtl",
                                wordSpacing: "-.4ch"
                            }}>
                            {" "}
                            {email.split("").reverse().join(" ")}
                        </span>
                    </div>
                )}
                {url && (
                    <div>
                        <a
                            style={{
                                wordWrap: "normal",
                                textOverflow: "ellipsis",
                                overflow: "hidden"
                            }}
                            href={url}
                            target="_blank"
                            className="websiteLink"
                            rel="noopener noreferrer">
                            {url}
                        </a>
                    </div>
                )}
                <div id="contactInfoWrap" style={{ marginTop: "10px" }}>
                    {/*<div>{spkr_name}</div>*/}
                    <SpeakerAddress
                        // address={address}
                        // address2={address2}
                        city={city}
                        state={state}
                        zip={zip}
                        country={country}
                    />
                </div>
            </div>
        );
    }
}

ProfileContactInfo.propTypes = {
    speaker: PropTypes.object
};

export default ProfileContactInfo;
